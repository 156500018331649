<script setup>
import { reactive, ref, onMounted, getCurrentInstance } from "vue"
import { Plus, CopyDocument } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import dayjs from "dayjs"

const { appContext } = getCurrentInstance()

const props = {
  expandTrigger: 'hover',
}
let options = reactive([])
let optionsPlugin = ref([])
let optionsBrand = ref([])

const onChangeBrand = (val) => {
  newDeviceData.value.brandID = val[0]
  newDeviceData.value.pluginPid = val[1]
  if (val[1]) {
    getCongMingPayPluginChildren(val[1])
  }
  let selectItem = optionsBrand.value.find(item => item.value == val[0])
  let categoryName = selectItem.label
  if (val[1]) {
    categoryName += '/' + selectItem.children.find(item => item.value == val[1]).label
  }
  newDeviceData.value.categoryName = categoryName
}

const onChangeSoftUid = (val) => {
  let selectItem = optionsPlugin.value.find(item => item.uuid == val)
  newDeviceData.value.categoryName += '/' + selectItem.name
  console.log(newDeviceData.value.categoryName)
}

const onChangeAgent = (val) => {
  newDeviceData.value.merchant_id = undefined
  getOptionsMerchant({ agent_id: val })
}

const optionsAgent = ref([])
const getOptionsAgent = (data) => {
  appContext.config.globalProperties.$api.dict.getAgentDict(data).then(res => {
    if (res.status == 200) {
      optionsAgent.value = res.data.data
    }
  })
}

const optionsMerchant = ref([])
const getOptionsMerchant = (data) => {
  appContext.config.globalProperties.$api.dict.getMerchantDict(data).then(res => {
    if (res.status == 200) {
      optionsMerchant.value = res.data.data
    }
  })
}

const onChangeMerchant = (val) => {
  newDeviceData.value.store_id = undefined
  getOptionsStore({ merchant_id: val })
}

const optionsStore = ref([])
const getOptionsStore = (data) => {
  appContext.config.globalProperties.$api.dict.getStoreDict(data).then(res => {
    if (res.status == 200) {
      optionsStore.value = res.data.data
    }
  })
}



// 设备型号选中后
const handleChange = (val) => {
  newDeviceData.value.device_type_id = val[1]
}

const handleChangeB = (val) => {
  editorDate.value.device_type_id = val[1]
}

let newDialog = ref(false)
const hanleNewDevice = () => {
  newDialog.value = !newDialog.value
}
let newDeviceData = ref({
  status: 1,
  number: 1
})
const rules = reactive({
  device_type_id: [
    {
      required: true,
      message: '设备型号不能为空',
      trigger: 'blur',
    }
  ],
  status: [
    {
      required: true,
      message: '状态不能为空',
      trigger: 'change',
    }
  ],
  number: [
    {
      required: true,
      message: "设备数量不能为空",
      trigger: "change"
    },
  ]
})
const addDeviceRef = ref()
const hanleSeveNewDevice = async (ele) => {
  // return console.log('debug', newDeviceData.value, ele)
  if (!ele) return
  await ele.validate((valid, fields) => {
    if (valid) {
      appContext.config.globalProperties.$api.agents.newAddDevice(newDeviceData.value).then(res => {
        if (res.status == 200) {
          newDialog.value = false
          ElMessage({
            message: '设备添加成功',
            type: 'success',
          })
          newDeviceData.value.device_type_id = ""
          queryEquipmentList()
        } else {
          ElMessage({
            message: '设备添加失败,请稍后重试',
            type: 'error',
          })
          queryEquipmentList()
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}
const editorDeviceRef = ref()
const editor = ref(false)
const editorDate = ref()
const hanleEditorDevice = async (ele) => {
  if (!ele) return
  await ele.validate((valid, fields) => {
    if (valid) {
      let temp = {
        device_type_id: editorDate.value.device_type_id,
        mark: editorDate.value.mark,
        status: editorDate.value.status
      }
      appContext.config.globalProperties.$api.agents.editorDevice(editorDate.value.id, temp).then(res => {
        if (res.status === 200) {
          editor.value = false
          ElMessage({
            message: '修改成功',
            type: 'success',
          })
          queryEquipmentList()
        } else {
          ElMessage({
            message: '修改失败，请稍后重试',
            type: 'error',
          })
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 表格数据
let equipmentData = ref()
let pagination = reactive({
  page: 1, // 当前页
  total: 0,  // 总条数
  perPage: 0  // 每页显示几条
})

const handleCopy = (data) => {
  ElMessage({
    message: '复制成功',
    type: 'success',
  })
  let tempcopy = document.createElement("input")
  tempcopy.value = data
  document.body.appendChild(tempcopy)
  tempcopy.select()
  document.execCommand('Copy')
  document.body.removeChild(tempcopy); // 复制完成后，移除临时输入框
}
const handleEditor = (row) => {
  editorDate.value = row
  editor.value = !editor.value
}
const handleDelete = (row) => {
  appContext.config.globalProperties.$api.agents.delDevice(row.id).then(res => {
    if (res.status == 200) {
      ElMessage({
        message: '删除成功',
        type: 'success',
      })
      queryEquipmentList()
    } else {
      ElMessage({
        message: '删除失败！请稍后重试',
        type: 'error',
      })
    }
  })
}
const pageChange = (page) => {
  queryEquipmentList({ page: page })
}

let isloading = ref(true)
// 表格数据 end


const queryEquipmentList = (arg) => {
  isloading.value = true
  appContext.config.globalProperties.$api.agents.bindDevices(arg).then(res => {
    if (res.status == 200) {
      equipmentData.value = res.data.data
      pagination.page = res.data.pagination.currentPage
      pagination.total = res.data.pagination.total
      pagination.perPage = res.data.pagination.perPage
      isloading.value = !isloading.value
    }
  })
}


const serchOption = reactive({
  categoriesOption: [],
})

const queryDeviceTypes = () => {
  appContext.config.globalProperties.$api.agents.queryDeviceCategories().then(res => {
    if (res.status == 200) {
      res.data.data.forEach(item => {
        if (item.device_types.length > 0) {
          let new_ary = { ...item }
          new_ary["value"] = item.id
          new_ary["label"] = item.name
          new_ary["children"] = item.device_types.map((citem) => {
            citem["value"] = citem.id
            citem["label"] = citem.name
            return citem
          })
          options.push(new_ary)
        }
      })
      serchOption.categoriesOption = options
    }
  })
}

const getCongMingPayCategory = () => {
  appContext.config.globalProperties.$api.thirdPartyDevice.congMingPayCategory().then(r => {
    if (r.status == 200) {
      optionsBrand.value = r.data.data
    }
  })
}

const getCongMingPayPluginChildren = (pid) => {
  appContext.config.globalProperties.$api.thirdPartyDevice.congMingPayPluginChildren({ pid }).then(r => {
    if (r.status == 200) {
      optionsPlugin.value = r.data.data
    }
  })
}


onMounted(() => {
  queryEquipmentList()
  queryDeviceTypes()
  queryAgents()
  getCongMingPayCategory()
  getOptionsAgent()
})

const handleChangeDeviceNme = (val) => {
  newDeviceData.value.number = newDeviceData.value.device_type_id == 11 ? 1 : val
}

const serchInline = reactive({})

const handleChangeCascader = (val) => {
  serchInline.device_category_id = val[val.length - 1]
}

const queryAgents = () => {
  appContext.config.globalProperties.$api.agentsList({ pageSize: "999" }).then(res => {
    if (res.status == 200) {
      serchOption.agentOption = treeToArray(res.data)
    }
  })
}

const treeToArray = (tree) => {
  const res = []
  let list = {}
  for (const item of tree) {
    list[item.id] = { ...item, value: item.id, label: item.company, children: [] }
  }
  for (const item of tree) {
    const newItem = list[item.id]
    if (item.parent_id === null) {
      res.push(newItem)
    } else {
      if (Object.prototype.hasOwnProperty.call(list, item.parent_id)) {
        list[item.parent_id].children.push(newItem)
      }
    }
  }
  return res
}

const handleChangeAgents = (val) => {
  serchInline.agent_id = val[val.length - 1]
}

const handleSerch = () => {
  console.log(serchInline);
}

</script>

<template>
  <div class="container">
    <div class="table-container">
      <el-card shadow="never">
        <el-form :inline="true" :model="serchInline">
          <el-form-item label="商户订单号">
            <el-input v-model="serchInline.serial_number" placeholder="请输入商户订单号" />
          </el-form-item>
          <el-form-item label="设备类型">
            <el-cascader v-model="serchInline.device_category_id" :options="serchOption.categoriesOption"
              @change="handleChangeCascader">
              <template #default="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item label="代理商">
            <el-cascader v-model="serchInline.agent_id" :options="serchOption.agentOption" @change="handleChangeAgents"
              :props="{ checkStrictly: true }">
              <template #default="{ node, data }">
                <span>{{ data.label }}</span>
                <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
              </template>
            </el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSerch">搜索</el-button>
          </el-form-item>
        </el-form>
      </el-card>
      <div class="table-header">
        <el-button type="primary" @click="hanleNewDevice()">
          <el-icon>
            <Plus />
          </el-icon>
          添加设备
        </el-button>
      </div>
      <div class="table-content">
        <el-table :data="equipmentData" style="width: 100%" v-loading="isloading" size="small">
          <el-table-column label="序号" width="80" type="index" align="center">
            <template #default="scope">
              {{ (pagination.page - 1) * pagination.perPage + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="serial_number" label="商户订单号" width="200px" align="center">
            <template #default="scope">
              <span style="margin-right:4px"> {{ scope.row.serial_number }}</span>
              <el-icon>
                <CopyDocument @click="handleCopy(scope.row.serial_number)" style="color:#52acff" />
              </el-icon>
            </template>
          </el-table-column>
          <el-table-column prop="device_category.name" label="设备类型" width="200px" align="center">
            <template #default="scope">
              <el-tag effect="plain">{{ scope.row.device_category.name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="device_type.name" label="设备型号" width="200px" align="center">
            <template #default="scope">
              <el-tag type="warning" effect="plain">{{ scope.row.device_type.name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="agent.name" label="所属代理商" width="200px" align="center" />
          <el-table-column label="状态" prop="status" align="center">
            <template #default="scoped">
              <el-tag v-if="scoped.row.status == 1" type="success" effect="plain">已启用</el-tag>
              <el-tag v-else type="danger" effect="plain">已禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="created_at" label="创建时间" align="center">
            <template #default="scope">
              <div style="text-align: center;">
                <span>{{ dayjs(scope.row.created_at).format("YYYY-MM-DD HH:mm:ss") }} </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <div class="control">
                <el-link :underline="false" type="primary" @click="handleEditor(scope.row)">编辑</el-link>
                <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row)">
                  <template #reference>
                    <el-link :underline="false" type="primary">删除</el-link>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty description="空数据" :image-size="200" />
          </template>
        </el-table>
        <div class="pagination">
          <el-pagination background :page-size=15 layout="total, prev, pager, next" :total=pagination.total
            @current-change="pageChange" :current-page=pagination.page />
        </div>
      </div>
    </div>
  </div>
  <!-- NewDevice -->
  <el-dialog v-model="newDialog" title="添加设备" width="30%" draggable>
    <el-form ref="addDeviceRef" :model="newDeviceData" label-width="120px" label-position="right" :rules="rules">
      <el-form-item label="设备型号:" prop="device_type_id">
        <el-cascader v-model="newDeviceData.device_type_id" :options="options" :props="props" @change="handleChange" />
      </el-form-item>
      <el-form-item label="设备数量:" prop="number">
        <el-input-number v-model="newDeviceData.number" :min="1" :max="100"
          :readonly="newDeviceData.device_type_id == 11" @change="handleChangeDeviceNme" controls-position="right" />
      </el-form-item>
      <el-form-item label="聪明付设备分类:" prop="brandID" v-if="newDeviceData.device_type_id == 11">
        <el-cascader :options="optionsBrand" :props="props" @change="onChangeBrand" />
      </el-form-item>
      <el-form-item label="插件子分类:" prop="softUid" v-if="newDeviceData.brandID == 'yhc_v5'">
        <el-select v-model="newDeviceData.softUid" clearable filterable @change="onChangeSoftUid">
          <el-option v-for="item in optionsPlugin" :key="item.uuid" :label="item.name" :value="item.uuid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定代理商:" prop="softUid" v-if="newDeviceData.device_type_id == 11">
        <el-select v-model="newDeviceData.agent_id" placeholder="请选择代理商" filterable clearable style="width: 90%;"
          @change="onChangeAgent">
          <el-option v-for="option in optionsAgent" :key="option.id" :label="option.name"
            :value="option.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定商户:" prop="softUid" v-if="newDeviceData.device_type_id == 11">
        <el-select v-model="newDeviceData.merchant_id" placeholder="请输入商户号或昵称" filterable clearable style="width: 90%;"
          @change="onChangeMerchant">
          <el-option v-for="option in optionsMerchant" :key="option.id" :label="option.no + '-' + option.merchant_alias"
            :value="option.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="绑定门店:" prop="store_id" v-if="newDeviceData.device_type_id == 11">
        <el-select v-model="newDeviceData.store_id" placeholder="请选择门店" filterable clearable style="width: 90%;">
          <el-option v-for="option in optionsStore" :key="option.id" :label="option.id + '-' + option.name"
            :value="option.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态:" prop="status">
        <el-radio-group v-model="newDeviceData.status">
          <el-radio :label=1>开通</el-radio>
          <el-radio :label=0>关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注:">
        <el-input v-model="newDeviceData.mark" type="textarea" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="newDialog = false">取消</el-button>
        <el-button type="primary" @click="hanleSeveNewDevice(addDeviceRef)">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- NewDevice END -->

  <!-- 编辑 -->
  <el-dialog v-model="editor" title="修改设备" width="40%">
    <el-form ref="editorDeviceRef" :model="editorDate" label-width="100px" label-position="right" :rules="rules">
      <el-form-item label="设备型号：" prop="device_type_id">
        <el-cascader v-model="editorDate.device_type_id" :options="options" :props="props" @change="handleChangeB" />
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-radio-group v-model="editorDate.status">
          <el-radio :label=1>开通</el-radio>
          <el-radio :label=0>关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注：">
        <el-input v-model="editorDate.mark" type="textarea" placeholder="请输入备注" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editor = false">取消</el-button>
        <el-button type="primary" @click="hanleEditorDevice(editorDeviceRef)">确认</el-button>
      </span>
    </template>
  </el-dialog>
  <!-- 编辑 end -->
</template>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .table-container {
    padding: 6px 0;

    .table-header {
      display: flex;
      justify-content: flex-end;
      height: 60px;
      align-items: center;
      align-content: center;
    }

    .table-content {
      .control {
        display: flex;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
      }

      .pagination {
        margin: 1.25rem 0;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>
